<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <b-container v-if="!loading">
      <RichTextRenderer :document="galleryarticle.body.json" />
      <div class="row row-cols-1 row-cols-md-3">
        <div class="col mb-4" v-for="item in galleryarticle.imagesCollection.items" :key="item.publishDate">
          <div class="card">
            <img :src="item.url" class="card-img-top" :alt="item.description">
            <div class="card-body">
              <h5 class="card-title">{{ item.title }}</h5>
              <p class="card-text">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" />
      <div class="overflow-auto">
        <b-pagination-nav :link-gen="linkGen" :number-of-pages="this.maxPageNum" use-router></b-pagination-nav>
      </div>

    </b-container>
    <div class="mt-3" />
  </div>
</template>

<script>
import GalleryArticleService from '@/services/GalleryArticleService.js';
// reference: https://github.com/paramander/contentful-rich-text-vue-renderer/issues/9
// reference: https://github.com/paramander/contentful-rich-text-vue-renderer
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
var assert = require('assert');

export default {
  name: 'gallery',
  title: 'TPA Gembira - Gallery',
  data() {
    return {
      galleryarticle: {},
      currPage: 1,
      pageNum: 1,
      maxPageNum: 1,
      total:0, // total images
      skip:0, // dynamically change
      limit:6, // static variable
      loading: false,
    }
  },
  components: {
    RichTextRenderer
  },
  created(){
    this.getGalleryArticleData();
  },
  beforeRouteUpdate (to, from, next) {
    next();
    this.getGalleryArticleData();
  },
  methods: {
    async getGalleryArticleData(){
      this.loading = true;
      try {
        this.currPage = (typeof this.$route.query.page === "undefined") ? 1 : parseInt(this.$route.query.page);
        assert(this.currPage > 0);
        this.skip = (this.currPage-1) * this.limit;
      } catch {
        this.currPage = 1;
        this.skip = 0;
      }
      GalleryArticleService.getGalleryArticle(this.skip, this.limit)
        .then(
          (galleryarticle => {
            this.$set(this, "galleryarticle", galleryarticle);
            try {
              this.total = parseInt(galleryarticle.imagesCollection.total);
              this.maxPageNum = Math.ceil(this.total / this.limit); 
            } catch (error){
              this.total = 0;
              this.maxPageNum = 1;
            }
            this.loading = false;
          }).bind(this)
        );
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
  }
}
</script>

<style lang="sass" scoped>
</style>